@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?2i5l82");
  src: url("./fonts/icomoon.eot?2i5l82#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?2i5l82") format("truetype"),
    url("./fonts/icomoon.woff?2i5l82") format("woff"),
    url("./fonts/icomoon.svg?2i5l82#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-career-agile:before {
  content: "\e930";
}
.icon-ic-clock:before {
  content: "\e931";
}
.icon-ic-document-contract:before {
  content: "\e92f";
}
.icon-login:before {
  content: "\e92a";
}
.icon-NovoVisual:before {
  content: "\e92b";
}
.icon-setaCarrossel:before {
  content: "\e92c";
}
.icon-variedade:before {
  content: "\e92d";
}
.icon-www:before {
  content: "\e92e";
}
.icon-ic-store:before {
  content: "\e927";
}
.icon-ic-star:before {
  content: "\e928";
}
.icon-ic-team-management:before {
  content: "\e929";
}
.icon-ic-password-hide:before {
  content: "\e925";
}
.icon-ic-password-show:before {
  content: "\e926";
}
.icon-ic-email:before {
  content: "\e923";
}
.icon-ic-mobile:before {
  content: "\e924";
}
.icon-ic_circle_urgent:before {
  content: "\e922";
}
.icon-ic-bank-branch:before {
  content: "\e921";
}
.icon-ic-communication-message:before {
  content: "\e920";
}
.icon-ic-check:before {
  content: "\e91e";
}
.icon-ic-security:before {
  content: "\e91f";
}
.icon-ic-card:before {
  content: "\e91d";
}
.icon-ic-internet-banking:before {
  content: "\e91c";
}
.icon-ic-bank-credit:before {
  content: "\e91a";
}
.icon-ic-checking-account-open:before {
  content: "\e91b";
}
.icon-ic-circle-add:before {
  content: "\e917";
}
.icon-ic-circle-igual:before {
  content: "\e918";
}
.icon-ic-circle-update-sync:before {
  content: "\e919";
}
.icon-ic-bank-transactions:before {
  content: "\e916";
}
.icon-ic-vehicle-transport-services:before {
  content: "\e915";
}
.icon-group .path1:before {
  content: "\e910";
  color: rgb(0, 0, 0);
}
.icon-group .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic-savings-account:before {
  content: "\e912";
}
.icon-ic-std-logo:before {
  content: "\e913";
}
.icon-ic-std-way:before {
  content: "\e914";
}
.icon-x:before {
  content: "\e90f";
  color: #000;
}
.icon-ic-circle-account-user:before {
  content: "\e90e";
}
.icon-ic-filter:before {
  content: "\e90d";
}
.icon-ic-calendar:before {
  content: "\e90c";
}
.icon-arrow-right:before {
  content: "\e90b";
}
.icon-ic-companies:before {
  content: "\e900";
}
.icon-ic-international:before {
  content: "\e901";
}
.icon-ic-location:before {
  content: "\e902";
}
.icon-ic-menu:before {
  content: "\e903";
}
.icon-ic-search:before {
  content: "\e904";
}
.icon-ic-social-facebook:before {
  content: "\e905";
}
.icon-ic-social-instagram:before {
  content: "\e906";
}
.icon-ic-social-twitter:before {
  content: "\e907";
}
.icon-ic-supermarket-cart:before {
  content: "\e908";
}
.icon-ic-travel:before {
  content: "\e909";
}
.icon-ic-vehicle-car:before {
  content: "\e90a";
}
